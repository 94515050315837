import { Directive, HostListener, Input } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

@Directive({
  selector: 'a[sgp-seguranca]'
})
export class SgpSegurancaDirective {

  @Input('href') href: string = 'text/plain';
  @Input('default-filename') defaultFilename: string = 'download';
  
  constructor(
    private http: HttpClient
  ) { }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    // Prevent default behavior when clicking a link
    event.preventDefault();

    // Get url from href
    if (!this.href) {
      console.log('[SgpSegurancaDirective] Falha ao obter "href"')
      return;
    }
    
    this.http.get(this.href, {
      observe: 'response',
      responseType: 'arraybuffer'
    }).subscribe({
      next: (response) => {
          const filename = this.getFileName(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob([response.body!]));
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
      },
      error: error => {
        console.log('[SgpSegurancaDirective] Falha no dowload:', this.href)
      }
    });
  }

  getFileName<T>(response: HttpResponse<T>) {
    let filename: string;
    try {
      const contentDisposition = response.headers.get('content-disposition') || '';
      const regex = /filename=(?:")?([^";]*)"?(;|$)/
      filename = regex.exec(contentDisposition)![1];
    }
    catch (e) {
      filename = this.defaultFilename
    }
    return filename;
  }
}
