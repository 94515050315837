<div class="container" *ngIf="movimentacao">

    <div class="row mt-2">
        <div class="col-3">
            <div class="label">Data de Processamento:</div>
        </div>
        <div class="col-9">
            <div class="value" data-cy="dataHoraProcessamentoSeguranca">{{ movimentacao.dataHoraProcessamentoSeguranca | date: "short" }}</div>
        </div>
    </div>
    
    <div class="row mt-2">
        <div class="col-3">
            <div class="label">Data do Evento SIAPE:</div>
        </div>
        <div class="col-9">
            <div class="value" data-cy="dataHoraMovimentacao">{{ movimentacao.dataHoraMovimentacao| date: "short" }}</div>
        </div>
    </div>
    
    <div class="row mt-2">
        <div class="col-3">
            <div class="label">Órgão Atual:</div>
        </div>
        <div class="col-9">
            <div class="value" data-cy="codigoOrgaoAtual">
                <app-descricao-orgao [codigoSiape]="movimentacao.codigoOrgaoAtual"></app-descricao-orgao>
            </div>
        </div>
    </div>
    
    <div class="row mt-2">
        <div class="col-3">
            <div class="label">Órgão Novo:</div>
        </div>
        <div class="col-9">
            <div class="value" data-cy="codigoOrgaoNovo">
                <app-descricao-orgao [codigoSiape]="movimentacao.codigoOrgaoNovo"></app-descricao-orgao>
            </div>
        </div>
    </div>
    
    <div class="row mt-2">
        <div class="col-3">
            <div class="label">UPAG Atual:</div>
        </div>
        <div class="col-9">
            <div class="value" data-cy="codigoUPAGAtual">
                <app-descricao-uorg 
                    [codigoSiapeOrgao]="movimentacao.codigoOrgaoAtual" 
                    [codigoSiapeUorg]="movimentacao.codigoUpagAtual">
                </app-descricao-uorg>
            </div>
        </div>
    </div>
    
    <div class="row mt-2">
        <div class="col-3">
            <div class="label">UPAG Nova:</div>
        </div>
        <div class="col-9">
            <div class="value" data-cy="codigoUPAGNova">
                <app-descricao-uorg 
                    [codigoSiapeOrgao]="movimentacao.codigoOrgaoNovo" 
                    [codigoSiapeUorg]="movimentacao.codigoUpagNovo">
                </app-descricao-uorg>
            </div>
        </div>
    </div>
    
    <div class="row mt-2">
        <div class="col-3">
            <div class="label">UORG Atual:</div>
        </div>
        <div class="col-9">
            <div class="value" data-cy="codigoUORGAtual">
                <app-descricao-uorg 
                    [codigoSiapeOrgao]="movimentacao.codigoOrgaoAtual" 
                    [codigoSiapeUorg]="movimentacao.codigoUorgAtual">
                </app-descricao-uorg>
            </div>
        </div>
    </div>
    
    <div class="row mt-2">
        <div class="col-3">
            <div class="label">UORG Nova:</div>
        </div>
        <div class="col-9">
            <div class="value" data-cy="codigoUORGNova">
                <app-descricao-uorg 
                    [codigoSiapeOrgao]="movimentacao.codigoOrgaoNovo" 
                    [codigoSiapeUorg]="movimentacao.codigoUorgNovo">
                </app-descricao-uorg>
            </div>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-3">
            <div class="label">CPF:</div>
        </div>
        <div class="col-9">
            <div class="value" data-cy="cpf">{{ movimentacao.cpfServidor | cpf }}</div>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-3">
            <div class="label">Matrícula Atual:</div>
        </div>
        <div class="col-9">
            <div class="value" data-cy="matriculaAtual">{{ movimentacao.matriculaAtual }}</div>
        </div>
    </div>
    
    <div class="row mt-2">
        <div class="col-3">
            <div class="label">Matrícula Nova:</div>
        </div>
        <div class="col-9">
            <div class="value" data-cy="matriculaNova">{{ movimentacao.matriculaNova }}</div>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-3">
            <div class="label">Tipo de Movimentação:</div>
        </div>
        <div class="col-9" data-cy="tipoMovimentacao">
            <div class="value">{{ tipoMovimentacao(movimentacao.codigoEventoSiape) | codigoDescricao }}</div>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-3">
            <div class="label">Data do Óbito:</div>
        </div>
        <div class="col-9">
            <div class="value" data-cy="dataObito">{{ movimentacao.dataObito }}</div>
        </div>
    </div>
    
    <div class="row mt-2">
        <div class="col-3">
            <div class="label">Operação Executada:</div>
        </div>
        <div class="col-9">
            <div class="value" data-cy="acaoExecutada">{{ movimentacao.acaoExecutada }}</div>
        </div>
    </div>
    
    <div class="row mt-2">
        <div class="col-3">
            <div class="label">Código do Módulo Assinante do SIAPE:</div>
        </div>
        <div class="col-9">
            <div class="value" data-cy="codigoModuloAssinanteSiape">{{ movimentacao.codigoModuloAssinanteSiape }}</div>
        </div>
    </div>
    
    <div class="row mt-2">
        <div class="col-3">
            <div class="label">Confirmação da leitura pelo Módulo:</div>
        </div>
        <div class="col-9">
            <div class="value" data-cy="indLeituraModAssEvento">
                <i class="fas fa-check-circle text-green-50" *ngIf="movimentacao.indLeituraModAssEvento; else unchecked"></i>
                <ng-template #unchecked>
                    <i class="fas fa-times-circle text-red-50"></i>
                </ng-template>
                {{ movimentacao.indLeituraModAssEvento ? 'Sim' : 'Não'}}
            </div>
        </div>
    </div>
    
    <div class="row mt-2">
        <div class="col-3">
            <div class="label">Trata habilitação:</div>
        </div>
        <div class="col-9">
            <div class="value" data-cy="inTrataHabilitacao">{{ movimentacao.inTrataHabilitacao || '-' }}</div>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-3">
            <div class="label">Código de retorno do Autoriza:</div>
        </div>
        <div class="col-9">
            <div class="value" data-cy="autorizaRetornoCode">
                <i class="fas fa-check-circle text-green-50" *ngIf="autorizaRetornoOk"></i>
                <i class="fas fa-times-circle text-red-50" *ngIf="autorizaRetornoFail"></i>
                {{ movimentacao.autorizaRetornoCode }}
            </div>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-3">
            <div class="label">Exceção Lançada pelo Autoriza:</div>
        </div>
        <div class="col-9">
            <div class="value" data-cy="mensagemErro">{{ movimentacao.mensagemErro }}</div>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-3">
            <div class="label">Operação Executada Manualmente:</div>
        </div>
        <div class="col-9">
            <div class="value" data-cy="mensagemErro">{{ movimentacao.justificativaAcaoManual }}</div>
        </div>
    </div>
    
    <div class="row mt-6 p-justify-center">
        <div class="col-md-1" data-cy="botaVoltar">
            <a [href]="returnUrl" pButton type="button" label="Voltar" class="primary mt-3 mt-sm-0 ml-sm-3"></a>
        </div>
    </div>
</div>
