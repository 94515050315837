import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { HistoricoMovimentacoesService, MovimentacaoFuncionalResult } from '../../core/services/historico-movimentacoes.service';

import { HistoricoMovimentacoesParams } from 'src/app/core/models/movimentacao-funcional';
import { LazyLoadEvent, PrimeTemplate } from 'primeng/api';
import { TipoMovimentacao } from './../../core/models/movimentacao-funcional';
import { tiposMovimentacao } from '../../core/models/tipos-movimentacao';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalComponent } from '../modal/modal.component';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-historico-movimentacoes-pesquisa',
  templateUrl: './historico-movimentacoes-pesquisa.component.html',
  styleUrls: ['./historico-movimentacoes-pesquisa.component.scss'],
})
export class HistoricoMovimentacoesPesquisaComponent implements OnInit {

  first = 0;
  page = 0
  size = 10;
  
  filtros: HistoricoMovimentacoesParams = {}
  
  result?: MovimentacaoFuncionalResult;

  urlDownload: string = "";

  loading = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private historicoMovimentacaoService: HistoricoMovimentacoesService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    if (queryParams['page']) {
      this.page = Number(queryParams['page']);
    }
    if (queryParams['size']) {
      this.size = Number(queryParams['size']);
    }
    this.atualizarUrlDownload();
    this.modalService.loadTrigger$.subscribe(() => {
      this.load();
    });
  }

	paginar(event: LazyLoadEvent) {
    this.first = event.first ?? 0;
    this.size = event.rows ?? 10;
    this.page = this.first / this.size;
    this.router.navigate([], { queryParams: { page: this.page, size: this.size}, queryParamsHandling: "merge" });
		this.load();
	}

  get content() {
    // console.log(`get content ${this.result?.content.length}`)
    //return this.result?.content ?? [];
    return this.result?.movimentacoes ?? [];
  }

  get totalElements() {
    return this.result?.totalElements ?? 0;
  }
  
  async pesquisar(filtros: HistoricoMovimentacoesParams) {
    this.filtros = filtros;
    const urlSearchParams = this.historicoMovimentacaoService.tratarFiltros(this.filtros, this.page, this.size);
    const queryParams = Object.fromEntries(urlSearchParams);
    this.router.navigate([], { queryParams: queryParams });
    this.atualizarUrlDownload();
    await this.load();
    //console.log(this.result)
  }

  async load() {
    this.loading = true;
    this.cdRef.detectChanges();
    console.log('Page is ', this.page)
    this.result = await this.historicoMovimentacaoService
      .consultar(this.filtros, this.page, this.size);
    this.loading = false;
    //console.log(this.result)
  }

  atualizarUrlDownload() {
    this.urlDownload = this.historicoMovimentacaoService.getUrlDownload(this.filtros);
  }

  tipoMovimentacao(codigo: number): TipoMovimentacao | undefined {
    return tiposMovimentacao.find(x => x.codigo === codigo);
  }

  get returnUrl() {
    return this.router.url;
  }

  showModalDialog(id: string) {
    this.modalService.openDialog(id);
  }
} 
