export const tiposMovimentacao = [
    {
        "codigo": 1,
        "descricao": "REFORMA ENTRE ORGAOS E REDISTRIBUICAO DE SERV"
    },
    {
        "codigo": 2,
        "descricao": "REFORMA ENTRE ORGAOS DE SERVIDORES INATIVOS"
    },
    {
        "codigo": 3,
        "descricao": "REFORMA ENTRE ORGAOS E TRANSF. DE INSTIT. DE PENSAO E PENSI"
    },
    {
        "codigo": 4,
        "descricao": "ALTERACAO DE UPAG"
    },
    {
        "codigo": 5,
        "descricao": "ALTERACAO DE UORG DE EXERCICIO DENTRO DO MESMO ORGAO"
    },
    {
        "codigo": 6,
        "descricao": "INCLUSAO DE NOVO VINCULO PARA UM CPF"
    },
    {
        "codigo": 7,
        "descricao": "EXCLUSAO DE UM VINCULO DE UM CPF"
    },
    {
        "codigo": 8,
        "descricao": "INCLUSAO DE NOVO PENSIONISTA"
    },
    {
        "codigo": 9,
        "descricao": "EXCLUSAO DE PENSIONISTA"
    },
    {
        "codigo": 10,
        "descricao": "MUDANCA DE SITUACAO FUNCIONAL"
    },
    {
        "codigo": 11,
        "descricao": "ENCERRAMENTO DE FUNCAO INTERNA"
    },
    {
        "codigo": 12,
        "descricao": "REATIVACAO DO ANISTIADO VIA RECADASTRAMENTO"
    },
    {
        "codigo": 13,
        "descricao": "APOSENTADORIA"
    },
    {
        "codigo": 14,
        "descricao": "DESATIVACAO DO ANISTIADO VIA RECADASTRAMENTO"
    },
    {
        "codigo": 15,
        "descricao": "OBITO DE SERVIDOR VIA SCO (SISOB)"
    },
    {
        "codigo": 16,
        "descricao": "ALTERACAO DE UPAG POR APOSTILAMENTO"
    },
    {
        "codigo": 17,
        "descricao": "ALTERACAO DE UORG DE EXERCICIO POR APOSTILAMENTO"
    },
    {
        "codigo": 18,
        "descricao": "ATUALIZACAO DE DADOS CADASTRAIS"
    },
    {
        "codigo": 19,
        "descricao": "DESIGNACAO DE SUBSTITUTO"
    },
    {
        "codigo": 20,
        "descricao": "DESTITUICAO DE SUBSTITUTO"
    },
    {
        "codigo": 21,
        "descricao": "DESTITUICAO DE CHEFE"
    },
    {
        "codigo": 22,
        "descricao": "DESAPOSENTADORIA"
    },
    {
        "codigo": 23,
        "descricao": "NOMEACAO DE CHEFE"
    },
    {
        "codigo": 24,
        "descricao": "CANCELA ENCERRAMENTO DA APOSENTADORIA"
    }
]