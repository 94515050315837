export const tipoAcaoExecutada = [
    {
        "tipo": "TRANSFERIR_ATRIBUICOES"
    },
    {
        "tipo": "REMOVER_ATRIBUICOES"
    },
    {
        "tipo": "REMOVER_ATRIBUICOES_ORGAO_ESPECIFICO"
    },
    {
        "tipo": "DESCARTADA"
    },
    {
        "tipo": "DESCARTADA_POR_EXCEPCIONALIDADE_ORGAO"
    },
    {
        "tipo": "ERRO"
    },
    {
        "tipo": "TRATADA_MANUALMENTE"
    }
]