import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MovimentacaoFuncional, TipoMovimentacao } from './../../core/models/movimentacao-funcional';

import { HistoricoMovimentacoesService } from './../../core/services/historico-movimentacoes.service';
import { tiposMovimentacao } from 'src/app/core/models/tipos-movimentacao';

@Component({
  selector: 'app-historico-movimentacoes-detalhe',
  templateUrl: './historico-movimentacoes-detalhe.component.html',
  styleUrls: ['./historico-movimentacoes-detalhe.component.css']
})
export class HistoricoMovimentacoesDetalheComponent implements OnInit {

  idMovimentacao?: string | null;
  movimentacao?: MovimentacaoFuncional | null;

  constructor(
    private route: ActivatedRoute,
    private historicoMovimentacaoService: HistoricoMovimentacoesService,
  ) {}
  
  ngOnInit(): void {
      this.idMovimentacao = this.route.snapshot.paramMap.get('id');
      this.carregarMovimentacao();
  }

  async carregarMovimentacao() {
    if (this.idMovimentacao) {
      this.movimentacao = await this.historicoMovimentacaoService.obter(this.idMovimentacao);
    }
  }

  tipoMovimentacao(codigo: number): TipoMovimentacao | undefined {
    return tiposMovimentacao.find(x => x.codigo === codigo);
  }

  get autorizaRetornoOk(): boolean {
    return this.movimentacao?.autorizaRetornoCode?.startsWith('2') || false;
  }

  get autorizaRetornoFail(): boolean {
    if (this.movimentacao?.autorizaRetornoCode) {
      return !this.movimentacao.autorizaRetornoCode.startsWith('2')
    }
    return false;
  }

  get returnUrl() {
    const { returnUrl } = history.state;
    if (returnUrl) {
      return returnUrl as string;
    } else {
      return "/historico";
    }
  }

}
