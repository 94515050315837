import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'codigoDescricao'
})
export class CodigoDescricaoPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): string {
    return value ? `${value.codigo} - ${value.descricao}` : "";
  }

}
