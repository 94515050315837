import { Component, Input, OnInit } from '@angular/core';

import { EorgService } from '../../core/services/eorg.service';
import { Orgao } from '../../core/models/eorg-types';

@Component({
  selector: 'app-descricao-orgao',
  template: `
    <span [class.alt-msg]="altMsgClass">{{ msg }}</span>
  `,
  styles: [
    ".alt-msg { font-style: italic; }"
  ]
})
export class DescricaoOrgaoComponent implements OnInit {
  
  @Input() codigoSiape?: number | string;

  @Input() somenteSigla = false;

  orgao?: Orgao;

  msg = "carregando...";
  altMsgClass = true;
  
  constructor(
    private eorgService: EorgService,
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.codigoSiape) {
      this.orgao = await this.eorgService.consultarOrgaoPeloCodigoSiape(this.codigoSiape);

      if (this.orgao) {
        const msg = `${this.orgao.codigoSiape} - ${this.orgao.sigla}`;
        this.msg = this.somenteSigla ? msg : `${msg} - ${this.orgao.nome}`;
      } else {
        console.log(`Orgão ${this.codigoSiape} não encontrado`);
        this.msg = this.codigoSiape.toString();
      }
    } else if (this.codigoSiape == 0) {
      this.msg = "0";
    } else {
      this.msg = "";
    }
    this.altMsgClass = false;
  }
}
