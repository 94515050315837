import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ActivatedRoute } from '@angular/router';
import { EorgService } from './../../core/services/eorg.service';
import { HistoricoMovimentacoesParams, TipoAcaoExecutada } from 'src/app/core/models/movimentacao-funcional';
import { HistoricoMovimentacoesService } from './../../core/services/historico-movimentacoes.service';
import { Orgao } from './../../core/models/eorg-types';
import { TipoMovimentacao } from './../../core/models/movimentacao-funcional';
import { notBeforeValidator } from 'src/app/core/validators/not-before.validator';

@Component({
  selector: 'app-historico-movimentacoes-filtros',
  templateUrl: './historico-movimentacoes-filtros.component.html',
  styleUrls: ['./historico-movimentacoes-filtros.component.css']
})
export class HistoricoMovimentacoesFiltrosComponent implements OnInit {

  @Output() pesquisa = new EventEmitter<HistoricoMovimentacoesParams>();
  
  filtros: FormGroup;

  tiposEventos: TipoMovimentacao[] = [];

  tiposAcaoExecutada: TipoAcaoExecutada[] = [];

  orgaos: Orgao[] = [];

  defaultDataInicio: Date = this.calcDefaultDataInicio();

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private eorgService: EorgService,
    private historicoMovimentacoesService: HistoricoMovimentacoesService
  ) {
    this.filtros = this.fb.group({
      cpfServidor: [undefined],
      matricula: [undefined],
      codigoOrgao: [undefined],
      codigoEventoSiape: [undefined],
      acaoExecutada: [undefined],
      dataInicio: [undefined],
      dataFim: [undefined],
    }, { validators: notBeforeValidator('dataInicio', 'dataFim') });
  }

  ngOnInit(): void {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    if (Object.keys(queryParams).length) {
      this.filtros.patchValue(queryParams);
    }
    this.carregarOrgaos();
    this.carregarTiposEventosMovimentacao();
    this.carregarTiposAcaoexecutada();
    this.pesquisar();
  }

  async carregarOrgaos() {
    this.orgaos = await this.eorgService.listarOrgaos();
    //console.log(`this.orgaos.length=${this.orgaos.length}`)
  }

  async carregarTiposEventosMovimentacao() {
    this.tiposEventos = await this.historicoMovimentacoesService.listarTiposMovimentacao();
    //console.log(`this.tiposEventos.length=${this.tiposEventos.length}`)
  }

  async carregarTiposAcaoexecutada() {
    this.tiposAcaoExecutada = await this.historicoMovimentacoesService.listarTiposAcaoExecutada();
    // console.log(this.tiposAcaoExecutada);
  }

  limpar() {
    this.filtros.reset();
    this.pesquisar();
  }

  pesquisar() {
    this.pesquisa.emit(this.filtros.value);
  }

  get orgaoSelecionado(): Orgao | undefined {
    const codigoOrgao = this.filtros.controls['codigoOrgao'].value;
    console.log(codigoOrgao);
    if (codigoOrgao && !!this.orgaos) {
      const selected = this.orgaos.find(o => o.codigoSiape == codigoOrgao);
      return selected;
    }
    return undefined;
  }

  get eventoSiapeSelecionado(): TipoMovimentacao | undefined {
    const codigoEventoSiape = this.filtros.controls['codigoEventoSiape'].value;
    if (codigoEventoSiape && !!this.tiposEventos) {
      const selected = this.tiposEventos.find(o => o.codigo == codigoEventoSiape);
      return selected;
    }
    return undefined;
  }

  get tipoAcaoExecutadaSelecionada(): TipoAcaoExecutada | undefined {
    const tipoAcaoExecutada = this.filtros.controls['acaoExecutada'].value;
    if (tipoAcaoExecutada && !!this.tiposAcaoExecutada) {
      const selected = this.tiposAcaoExecutada.find(o => o.tipo == tipoAcaoExecutada)
      return selected;
    }
    return undefined;
  }

  private calcDefaultDataInicio(): Date {
    const date = new Date();
    date.setHours(0, 0, 0);
    date.setDate(date.getDate() - 7);
    return date;
  }
} 
