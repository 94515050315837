import { Component, Input } from '@angular/core';

import { EorgService } from 'src/app/core/services/eorg.service';
import { Uorg } from './../../core/models/eorg-types';

@Component({
  selector: 'app-descricao-uorg',
  template: `
    <span [class.alt-msg]="altMsgClass">{{ msg }}</span>
  `,
  styles: [
    ".alt-msg { font-style: italic; }"
  ]
})
export class DescricaoUorgComponent {
  
  @Input() codigoSiapeOrgao?: number | string;

  @Input() codigoSiapeUorg?: number | string;

  @Input() somenteSigla = false;

  uorg?: Uorg;

  msg = "carregando...";
  
  altMsgClass = true;
  
  constructor(
    private eorgService: EorgService,
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.codigoSiapeOrgao && this.codigoSiapeUorg) {
      this.uorg = await this.eorgService.consultarUorgPeloCodigoSiape(this.codigoSiapeOrgao, this.codigoSiapeUorg);
      
      if (this.uorg) {
        const msg = `${this.uorg.codigoSiape} - ${this.uorg.sigla}`;
        this.msg = this.somenteSigla ? msg : `${msg} - ${this.uorg.nome}`;
      } else {
        console.log(`UORG ${this.codigoSiapeUorg} não encontrada`);
        this.msg = this.codigoSiapeUorg.toString();
      }
    } else if (this.codigoSiapeUorg == 0) {
      this.msg = "0";
    } else {
      this.msg = "";
    }
    this.altMsgClass = false;
  }
}
