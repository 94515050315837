<div class="filtros container" [formGroup]="filtros">
    <div class="row mt-2">
        <div class="field col-md-3">
            <label for="cpfServidor">CPF</label>
            <p-inputMask id="cpfServidor" data-cy="cpfServidor" name="cpfServidor" mask="999.999.999-99"
                formControlName="cpfServidor"></p-inputMask>
        </div>

        <div class="field col-md-3">
            <label for="matricula">Matrícula</label>
            <p-inputNumber data-cy="matricula" name="matricula" formControlName="matricula" [useGrouping]="false"
                [maxlength]="12"></p-inputNumber>
        </div>

        <div class="field col-md-3">
            <label for="eventoSiape">Tipo de Movimentação</label>
            <p-dropdown data-cy="eventoSiape" name="eventoSiape" [options]="tiposEventos"
                formControlName="codigoEventoSiape" optionValue="codigo" dataKey="codigo" [showClear]="true"
                placeholder=" ">
                <ng-template pTemplate="selectedItem">
                    {{eventoSiapeSelecionado | codigoDescricao}}
                </ng-template>
                <ng-template let-item pTemplate="item">
                    {{item | codigoDescricao}}
                </ng-template>
            </p-dropdown>
        </div>

        <div class="field col-md-3">
            <label for="acaoExecutada">Ação Executada</label>
            <p-dropdown data-cy="acaoExecutada" name="acaoExecutada" [options]="tiposAcaoExecutada"
                formControlName="acaoExecutada" optionValue="tipo" dataKey="tipo" [showClear]="true"
                placeholder=" ">
                <ng-template pTemplate="selectedItem">
                    {{ tipoAcaoExecutadaSelecionada?.tipo }}
                </ng-template>
                <ng-template let-item pTemplate="item">
                    {{ item.tipo }}
                </ng-template>
            </p-dropdown>
        </div>
    </div>

    <div class="row mt-2">
        <div class="field col-md-3 nowrap ellipsis">
            <label for="dataInicio">Período de Processamento - Início</label>
            <p-calendar data-cy="dataInicio" name="dataInicio" formControlName="dataInicio" [showTime]="true"
                [showButtonBar]="true" [defaultDate]="defaultDataInicio"></p-calendar>
        </div>

        <div class="field col-md-3 nowrap ellipsis">
            <label for="dataFim">Período de Processamento - Fim</label>
            <p-calendar data-cy="dataFim" name="dataFim" formControlName="dataFim" [showTime]="true"
                [showButtonBar]="true"></p-calendar>
            <div *ngIf="filtros.errors?.['notBefore'] && (filtros.touched || filtros.dirty)"
                class="cross-validation-error-message feedback danger">
                <i aria-hidden="true" class="fas fa-times-circle"></i>
                Data final menor que data inicial.
            </div>
        </div>

        <div class="field col-md-6">
            <label>Órgão</label>
            <p-dropdown data-cy="codigoOrgao" [options]="orgaos" formControlName="codigoOrgao" optionValue="codigoSiape"
                dataKey="codigoSiape" [showClear]="true" placeholder=" " [virtualScroll]="true" [itemSize]="30"
                [filter]="true" filterBy="codigoSiape,sigla,nome">
                <ng-template pTemplate="selectedItem">
                    {{orgaoSelecionado?.codigoSiape}} - {{orgaoSelecionado?.sigla}} - {{orgaoSelecionado?.nome}}
                </ng-template>
                <ng-template let-orgao pTemplate="item">
                    {{orgao?.codigoSiape}} - {{orgao.sigla}} - {{orgao.nome}}
                </ng-template>
            </p-dropdown>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-md-12">
            <button pButton type="button" data-cy="botao-pesquisar" label="Pesquisar"
                class="primary mt-3 mt-sm-0 ml-sm-3" (click)="pesquisar()" [disabled]="!filtros.valid"></button>
            <button data-cy="botao-limpar" pButton type="button" label="Limpar" class="secondary mt-3 mt-sm-0 ml-sm-3"
                (click)="limpar()"></button>
        </div>
    </div>
</div>