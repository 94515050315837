import { Injectable } from '@angular/core';
import { Message } from 'primeng/api';

/**
 * Service com métodos úteis de interface
 */
@Injectable({
	providedIn: 'root'
})
export class UiService {

	criarMensagem(tipo: string, titulo: string, texto: string): Message {
		return { severity: tipo, summary: titulo, detail: texto };
	}

}
