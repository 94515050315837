import { HttpClient, HttpParams } from '@angular/common/http';

import { ExcepcionalidadePaginated } from './../models/excepcionalidade-paginated';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExcepcionalidadeService {

	urlBase = environment.HOST_SEGURANCA_GESTOR + "/excepcionalidade";

	constructor(private http: HttpClient) {}

	findAllPaginado(pagina: number, tamanho: number): Observable<ExcepcionalidadePaginated> {
		let params = new HttpParams();

		if (pagina >= 0) {
			params = params.set('page', pagina);
		}

		if (tamanho) {
			params = params.set('size', tamanho);
		}
		return this.http.get<ExcepcionalidadePaginated>(this.urlBase, { params: params });
	}	

	salvar(codigoOrgao: string): Observable<any> {
		const url = this.urlBase + "/" + codigoOrgao;
		return this.http.post(url, {});
	}

	excluir(codigoOrgao: string): Observable<any> {
		const url = this.urlBase + "/" + codigoOrgao;
		return this.http.delete(url, {});
	}

}
