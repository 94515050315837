import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function notBeforeValidator(ctrl1Name: string, ctrl2Name: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    
    const ctrl1 = control.get(ctrl1Name);
    const ctrl2 = control.get(ctrl2Name);
    
    return ctrl1 && ctrl2 && 
          ctrl2.value && ctrl1.value && 
          ctrl2.value < ctrl1.value ? {
      notBefore: {
        value: ctrl2.value,
        reference: ctrl1.value
      }
    } : null;

  };
}
