import { LogLevel, SegurancaConfiguration } from '@sigepe/ngx-sigepe-seguranca';

export class ConfigEnvironment {
	host_seguranca_gestor: string;
	host_eorg: string;
	security_hosts: string[];
	host_login_unico?: string;
	host_jsks?: string;
	host_api_sigepe?: string;
	client_id?: string;
	cookieDomain?: string;

	constructor(
		host_seguranca_gestor: string,
		host_eorg: string,
		security_hosts: string[],
		host_login_unico?: string,
		host_jsks?: string,
		host_api_sigepe?: string,
		client_id?: string,
		cookieDomain?: string
	) {
		this.host_seguranca_gestor = host_seguranca_gestor;
		this.host_eorg = host_eorg;
		this.security_hosts = security_hosts;
		this.host_login_unico = host_login_unico;
		this.host_jsks = host_jsks;
		this.host_api_sigepe = host_api_sigepe;
		this.client_id = client_id;
		this.cookieDomain = cookieDomain;
	}

	getEnvironment() {
		return {
			HOST_SEGURANCA_GESTOR: `${this.host_seguranca_gestor}`,
			HOST_EORG: `${this.host_eorg}`,
			//Autoriza
			SEGURANCA_CONFIG: this.getConfiguracoesAutoriza()
		};
	}

	getConfiguracoesAutoriza(): SegurancaConfiguration {
		const seguranca_config: SegurancaConfiguration = {
			loginUnicoHost: this.host_login_unico,
			jwksHost: this.host_jsks,
			apiSigepeHost: this.host_api_sigepe,
			redirectUrl: `${window.location.protocol}//${window.location.host}/autorizacao`,
			postLogoutRedirectUri: `${window.location.protocol}//${window.location.host}`,
			cookieDomain: this.cookieDomain,
			clientId: this.client_id,
			scope: 'openid+email+phone+profile+govbr_confiabilidades', // 'openid profile ' + your scopes
			sistemaOrigem: 'SEGURANCA',
			responseType: 'code',
			silentRenew: false,
			renewTimeBeforeTokenExpiresInSeconds: 10,
			logLevel: LogLevel.Error,
			secureRoutes: this.security_hosts
		};
		return seguranca_config;
	}
}
