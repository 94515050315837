import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, lastValueFrom, pipe, shareReplay } from 'rxjs';
import { Orgao, Uorg } from '../models/eorg-types';

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EorgService {

	private readonly N10P9 = Math.pow(10, 9);

	private urlBase = environment.HOST_EORG;

	private cache: Map<String, HttpResponse<Object>> = new Map();
	private current: Map<String, Observable<HttpResponse<Object>>> = new Map();

	constructor(
		private http: HttpClient
	) {}

	async getCachedData<T extends Object>(url: string): Promise<HttpResponse<T>> {
		
		// Verifica no cache se possui request em andamento
		const cur = this.current.get(url);

		if (cur) {
			// console.log("[EorgService] Esperando request em andamento", url)
			const response = await lastValueFrom(cur);
			return response.clone() as HttpResponse<T>;
		}

		// Verifica no cache se já possui o dado
		const cachedResponse = this.cache.get(url);

		if (cachedResponse) {
			// console.log("[EorgService] Resultado do cache.", url)
			return cachedResponse.clone() as HttpResponse<T>;
		}

		// console.log("[EorgService] Obtendo dado remoto.", url)
		const request = this.http.get<T>(url, { observe: 'response' }).pipe(shareReplay(1));
		// Coloca no cache de requests em andamento
		this.current.set(url, request);
		const response = await lastValueFrom(request);
		// Remove do cache de requests em andamento
		this.current.delete(url);
		// Coloca no cache de requests finalizados
		this.cache.set(url, response.clone());
		
		return Promise.resolve(response);
	}

	async consultarOrgaoPeloCodigoSiape(codigoSiape: number | string): Promise<Orgao | undefined> {

		const url = `${this.urlBase}/orgao/siape/${codigoSiape}`;

		const response = await this.getCachedData(url);

		if (response.status == 204) {
			return Promise.resolve(undefined);
		} else if (response.status == 200) {
			const orgao =  await response.body as Orgao;
			return Promise.resolve(orgao);
		} else {
			const err = new Error("Falha na consulta ao EORG.");
			return Promise.reject(err);
		}
	}

	async consultarUorgPeloCodigoSiape(
		codigoSiapeOrgao: number | string,
		codigoSiapeUorg: number | string
	): Promise<Uorg | undefined> {

		const nCodigoSiapeUorg = this.truncarCodigoUorg(codigoSiapeUorg);

		const url = `${this.urlBase}/uorg/${codigoSiapeOrgao}/${nCodigoSiapeUorg}`;

		const response = await this.getCachedData(url);
		if (response.status == 204) {
			return Promise.resolve(undefined);
		} else if (response.status == 200) {
			const uorg =  await response.body as Uorg;
			return Promise.resolve(uorg);
		} else {
			const err = new Error("Falha na consulta ao EORG.");
			return Promise.reject(err);
		}
	}

	/**
	 * Remove o código do orgão se o codigo da UORG tiver mais de 10 possições.
	 * @param codigoSiapeUorg  código Siape da UORG
	 * @returns código da UORG sem o código do orgão
	 */
	private truncarCodigoUorg(codigoSiapeUorg: string | number) {
		
		const nCodigoSiapeUorg = Number(codigoSiapeUorg);

		if (nCodigoSiapeUorg >= this.N10P9) {
			return nCodigoSiapeUorg % this.N10P9;
		} else {
			return nCodigoSiapeUorg;
		}
	}

	async listarOrgaos(): Promise<Orgao[]> {

		const url = `${this.urlBase}/orgao`;
		const response = await this.getCachedData(url);
		if (response.ok) {
			let orgaos =  await response.body as Orgao[];
			// Em homologação houve um caso de Órgão Extinto sem codigoSiape nem 
			orgaos = orgaos
				.filter(o => o.codigoSiape)
				.sort(this.compareOrgaos);
			return Promise.resolve(orgaos);
		} else {
			const err = new Error("Falha na consulta ao EORG.");
			return Promise.reject(err);
		}
	}

	compareOrgaos(a: Orgao, b: Orgao): number {
		if (a.sigla > b.sigla) {
			return 1;
		} else if (a.sigla < b.sigla) {
			return -1;
		}
		
		if (a.nome > b.nome) {
			return 1;
		} else if (a.nome < b.nome) {
			return -1;
		}

		return 0;
	}
}
