<h3 data-cy="titulo" id="titulo">Histórico de Movimentações</h3>

<app-historico-movimentacoes-filtros (pesquisa)="pesquisar($event)"></app-historico-movimentacoes-filtros>

<div id="resultado" class="row mt-4" *ngIf="result">
    <div id="quantidadeResultados" class="col-11 mb-3">
        <p *ngIf="result.totalElements === 0">Nenhum resultado encontrado</p>
        <p *ngIf="result.totalElements === 1">Foi encontrado 1 registro</p>
        <p *ngIf="result.totalElements > 1">Foram encontrados {{ result.totalElements }} registros</p>
    </div>
    <div id="botoes-container" class="col-1 mb-3" *ngIf="!result || totalElements">
        <a [href]="urlDownload" sgp-seguranca pButton icon="fa fa-file-csv" class="secondary mt-3 mt-sm-0 ml-sm-3"></a>
    </div>
</div>

<p-table *ngIf="!result || totalElements"
[lazy]="true" 
(onLazyLoad)="paginar($event)"
[loading]="loading" 
[totalRecords]="totalElements" 
dataKey="id" 
[rowHover]="true" 
[responsive]="true"
responsiveLayout="stack" 
[autoLayout]="true" 
[paginator]="true" 
[(first)]="first"
[(rows)]="size"
[rowsPerPageOptions]="[10,20,50,100]"
(onLazyLoad)="paginar($event)"
[value]="content"
data-cy="tabela-historico-movimentacao"
dataKey="id" >
    <ng-template pTemplate="header">
        <tr>
            <!-- Dt/hora segurança - Dt/hora consulta siape - tipo movimentação - Tipo atualização no autoriza - órgão - matrícula - cpf - opção de detalhar -->
            <th>Data Processamento</th>
            <th>Data Evento SIAPE</th>
            <th>Tipo de Movimentação</th>
            <th>Ação Executada</th>
            <th>Órgão</th>
            <th>Matrícula</th>
            <th>CPF</th>
            <th>Ações</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
        <tr>
            <td>{{item.dataHoraProcessamentoSeguranca | date: 'short'}}</td>
            <td>{{item.dataHoraMovimentacao | date: 'short'}}</td>
            <td>{{tipoMovimentacao(item.codigoEventoSiape) | codigoDescricao}}</td>
            <td [ngClass]="{'text-semi-bold': item.acaoExecutada === 'ERRO'}">{{item.acaoExecutada}}</td>
            <td><app-descricao-orgao [codigoSiape]="item.codigoOrgaoAtual" [somenteSigla]="true"></app-descricao-orgao></td>
            <td>{{item.matriculaAtual}}</td>
            <td>{{item.cpfServidor | cpf}}</td>
            <td>
                <div class="botoes-container">
                    <button
                        [routerLink]="['/historico', item.id]"
                        [state]="{ returnUrl: returnUrl }"
                        id="botao-detalhes"
                        data-cy="botao-datalhes"
                        class="br-button circle"
                        type="button"
                        icon="fas fa-chevron-right"
                        title="Detalhar"
                        pButton
                    ></button>

                    <button
                        *ngIf="item.acaoExecutada === 'ERRO'"
                        id="botao-acao-manual"
                        data-cy="botao-acao-manual"
                        class="br-button circle"
                        type="button"
                        (click)="showModalDialog(item.id)"
                        icon="fa fa-hands"
                        title="AcaoManual"
                        pButton
                    ></button>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>


<app-modal></app-modal>