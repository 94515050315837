import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private openDialogSource = new Subject<string>();
  openDialog$ = this.openDialogSource.asObservable();

  private loadTrigger = new Subject<void>();
  loadTrigger$ = this.loadTrigger.asObservable();

  openDialog(id: string) {
    this.openDialogSource.next(id);
  }

  triggerLoad() {
    this.loadTrigger.next();
  }
}
